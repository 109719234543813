.portal_config_card{
    width: 98%;
    max-width: 1600px;
    background-color: var(--white);
    padding: 20px;
}

.portal_config_detail_top_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.portal_config_detail_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.portal_config_detail_left_container,
.portal_config_detail_right_container{
    width: 50%;
    padding: 10px 20px;
}

.portal_config_field_name{
    width: 50%;
    padding: 2px 0px;
    font-weight: bold; 
}

.portal_config_field_value_container{
    width: 100%;
}
.portal_config_field_value{
    width: 100%;
    padding: 5px;
    border: none;
}

.portal_config_field_value_editable {
    border: solid 1px  var(--lt_gray) ;
    width: 100%;
    padding: 5px;
}



.boldRed{
    color: red;
    font-weight: bold;
}

.middle_card {
    margin-top: 20px;
}

.portal_config_details_label{
    font-weight: bold;
    margin-bottom: 10px;
}

.portal_config_details_container {
    padding: 0px 5px;
}

.config_button_container{
    width: 98%;
    max-width: 1600px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0px 10px 0px;

}

.config_button {
    padding: 8px;
    min-width: 100px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 3px;
    background-color: var(--gray);
    color: var(--black);
}

.config_button:hover {
    background-color: var(--black);
    color: var(--gray);
    cursor: pointer;
}

.red_button{
    background-color: red;
    color: var(--black);
    
}
.orange_button{
    background-color: rgb(255, 140, 0);
    color: var(--white);
    
}
.yellow_button{
    background-color: rgb(240, 222, 14);
    color: var(--white);
    
}
.green_button{
    background-color: rgb(56, 126, 2);
    color: var(--white);
    
}
.black_button{
    background-color: var(--black);
    color: var(--white);
    
}
.portal_config_notes_container{
    background-color: var(--white);
    width: 98%;
    max-width: 1600px;
    min-height: 100px;
    padding: 0px 20px 20px 20px;
   
}

.portal_notes_button_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 20px;
}

.portal_notes_button{
    font-size: 18px;
    font-weight: bold;
    color: var(--blue);
    cursor: pointer;
}
.portal_notes_button:hover{
    font-weight: normal;
    color: var(--black);
    text-decoration: underline;
}

.portal_note_detail_container{
    margin-top: 10px;
    border: 1px solid var(--lt_gray);
    padding: 10px;
}
.portal_note_detail_title_container{
    display: flex;
    flex-direction: row;
}

.portal_note_detail{
    font-weight: bold;
    margin-right: 5px;
}

.ck-content {
    height: 200px;
}