.portal_ticket_newTicket_container{
    display: flex;
    flex-direction: row;
justify-content: end;
padding: 20px;

}

.portal_newTicket_button{
    font-weight: bold;
    background-color: var(--blue);
    color: var(--white);
    padding: 10px;
    border-radius: 5px;
}

.portal_newTicket_button:hover {
    background-color: var(--black);
}

.portal_ticket_filter_container{
    display: flex;
    flex-direction: row;

}

.portal_ticket_filter_column{
    padding: 5px;
    width: 33.3%
}

.portal_ticket_filter_search{
    display: block;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}

.portal_ticket_Select_input {
    padding: 8px 12px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}


.portal_ticket_table_header_row{
    background-color: var(--gray);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px 0px 0px;

}


.portal_ticket_table_header_item{
    padding: 10px;
    font-weight: bold;
    color: var(--black);
    width: 100%;
    text-align: center;
}

.portal_ticket_data_row {
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px 0px 0px;
    color: var(--black);
}

.portal_ticket_data_row:hover {
    background-color: var(--blue);
    color: var(--white);
    cursor: pointer;
}


.portal_ticket_data_item {
    padding: 10px;
    width: 100%;
    text-align: center; 
}

