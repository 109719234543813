.note_container {
background-color: var(--lt_gray);
padding: 20px;

}

.note_field_label{
    font-size: 16px;
    font-weight: bold;
    color: var(--blue);
    padding-left: 5px;
}
.note_textArea {
    display: block;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}

.note_button_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.note_dismiss_button{
    background-color: var(--dk_gray);
}

.note_dismiss_button:hover {
    background-color: var(--black);
}
.note_confirm_button:hover {
    background-color: var(--dk_gray);
}

.portal_note_button_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.portal_note_button{
    font-size: 12px;
    color: var(--md_gray);
    margin: 0px 10px;
    padding: 5px;
    border-radius: 5px;
}

.portal_note_button:hover {
    cursor: pointer;
    background-color: var(--dk_gray);
    color: var(--lt_gray);
}
.modal_field_label {
    color: var(--lt_gray)
}

.modal_button_container{
    padding: 10px;
}

.modal_dismiss_button{
    border-radius: 5px;
}
.modal_confirm_button{
    margin-left: 5px;
    border-radius: 5px;
}