.alert_body {
    position: absolute;
    z-index: 1;
    background-color: rgba(72, 75, 78, 0.80);
  }
  
  .alert_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .alert_card {
    width: 400px;
    height: 250px;
    border-radius: 16px;
    background-color: var(--white);
  }
  
  .alert_icon_container {
    display: flex;
    height: 32%;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--blue);
  }
  
  .alert_button_container {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
  }
  
  .alert_message {
    padding: 20px 10px;
    font-family: var(--primary_font);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  
  .alert_dismiss_button {
    background-color: var(--gray);
    color: var(--black);
    font-family: var(--primary_font);
    font-weight: bold;
    border-radius: 5px;
  }
  .alert_dismiss_button:hover {
    background-color: var(--black);
    color: var(--white);
    cursor: pointer;
  }
  .alert_confirm_button {
    margin-left: 10px;
    background-color: var(--blue);
    color: var(--white);
    font-family: var(--primary_font);
    border-radius: 5px;
  }
  .alert_confirm_button:hover {
    background-color: var(--dk_gray);
    color: var(--white);
    cursor: pointer;
  }

  .error_icon {
    font-size: 18px;
    font-weight: bold;
    color: var(--lt_gray);
  }

  @media screen and (max-width: 479px) {
    .alert_card {
        width: 320px;
        height: 225px;
      }
    
      .alert_message {
        font-size: 16px;
      }
    
      .alert_dismiss_button {
        font-size: 12px;
      }
    }    