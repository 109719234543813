.portal_ticket_emails_container{
    
    width: 98%;
    max-width: 1600px;
    min-height: 100px;
   
}

.portal_emails_button_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 20px;
}

.portal_emails_button{
    font-size: 18px;
    font-weight: bold;
    color: var(--blue);
    cursor: pointer;
}
.portal_emails_button:hover{
    font-weight: normal;
    color: var(--black);
    text-decoration: underline;
}

.portal_email_detail_container{
    background-color: var(--white);
    margin-top: 20px;
    border: 1px solid var(--lt_gray);
    padding: 20px;
}
.portal_email_detail_title_container{
    display: flex;
    flex-direction: row;
}

.portal_email_detail{
    font-weight: bold;
    margin-right: 5px;
}