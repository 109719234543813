@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

body{
    --blue: #2E68B1;
    --gray: #ccc;
    --lt_gray: #eeebeb;
    --dk_gray: #484b4e;
    --black: #161515;
    --white: #fff;
    --primary_font:'Open Sans', sans-serif; 
      
    }



.login_body {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--primary_font);
}

.login_content_container {
    min-width: 960px;
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_container {
    background-color: var(--white);
    width: 600px;
    border-radius: 10px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_title {
    padding: 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_logo {
width: 110px;
}

.login_input {
    display: block;
    width: 400px;
    padding:8px 12px;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 1.42857143;
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 10px;
}

.login_button {
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    color: var(--gray);
    background-color: #003760;
    font-family: var(--primary_font);
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

  .login_button:hover {
    background-color: var(--dk_gray);
    color: var(--white);
    cursor: pointer;
  }

.portal_body{
    display: flex;
    min-height: 110vh;
    flex-direction: column;
    align-items: center;
    background-color: var(--blue);
    padding: 20px 0px;
}

.portal_tickets_container{
    min-height: 80vh;
    width: 95%;
    max-width: 1600px;
    background-color: var(--white);
    padding: 0px 0px 20px 0px;
}