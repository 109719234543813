.header {
    display: flex;
    width: 100%;
    padding: 10px 0px;
    flex-direction: row;
    justify-content: Center;
    align-items: flex-start;
  }

  .menu_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 992px;
    max-width: 1600px;
    padding: 0px 20px;
  }

  .logo {
    width: 110px;
  }

  .logout_button {
    background-color: var(--blue);
    color: var(--white);
    font-weight: bold;
    padding: 8px;
    border-radius: 5px;
    font-size: 12px;
  }

  .logout_button:hover {
    cursor: pointer;
  }
  

  @media screen and (max-width: 991px) {
    .header {
        padding: 40px 0px;
      }

      .menu_container {
        min-width: 767px;
      }
  }

  @media screen and (max-width: 767px) {
    .menu_container {
        min-width: 500px;
      }
    .logo {
        width: 100px;
      }
  }


  @media screen and (max-width: 479px) {
  .menu_container {
    min-width: 320px;
    max-width: 479px;
  }

  .header {
    padding: 20px 0px;
    justify-content: end;
  }
}