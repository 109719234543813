

  
  .section-hero {
    display: flex;
    padding-top: 3%;
    padding-bottom: 5%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: url('../images/heroBackground.jpg');
    background-position: 0px 0px;
    background-size: cover;
  }
  
  .menu_container {
    display: flex;
    width: 100%;
    max-width: 1170px;
    padding-right: 0px;
    padding-left: 40px;
    justify-content: flex-end;
    align-items: center;
  }
  
  .hero-container {
    display: flex;
    max-width: 1170px;
    min-width: 990px;
    padding-left: 0px;
    flex-direction: row;
  }

  .form_error_message {
    color: rgb(235, 45, 45);
    font-weight: bold;
  }
  
  .hero-left {
    max-width: 50%;
  }
  
  .hero-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    max-width: 50%;
    align-items: center;
  }
  
  .hero-left-container {
    padding: 10px 20px 10px 0px;
  }
  
  .heading {
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: 'Encode Sans', sans-serif;
    color: #fff;
    font-size: 38px;
    line-height: 44px;
  }
  
  .paragraph {
    margin-top: 10px;
    font-family: Lato, sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
  }
  
  .heading-2 {
    font-family: 'Encode Sans', sans-serif;
    color: #fff;
    font-size: 30px;
    line-height: 33px;
    font-weight: 300;
  }
  
  .text-block {
    color: #fff;
  }
  
  .bold-text {
    font-family: Lato, sans-serif;
    color: #f58220;
    font-size: 18px;
  }
  
  .hero-header {
    margin-bottom: 30px;
  }
  
  .hero-right-container {
    min-width: 100%;
    padding: 60px 40px 40px;
  }
  
  .hero_form_text_field {
    border: 1px none #000;
    border-radius: 12px;
    background-color: rgba(28, 36, 41, 0.6);
    color: #fff;
    font-size: 14px;
  }
  
  .hero_form_text_field::-webkit-input-placeholder {
    color: #fff;
  }
  
  .hero_form_text_field:-ms-input-placeholder {
    color: #fff;
  }
  
  .hero_form_text_field::-ms-input-placeholder {
    color: #fff;
  }
  
  .hero_form_text_field::placeholder {
    color: #fff;
  }
  
  .hero_form_text_field.margin_left {
    margin-left: 0px;
    background-color: rgba(28, 36, 41, 0.6);
  }
  
  .hero_form_text_field.multiline {
    height: 100px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero_form_row {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .hero_form_row.opt-in {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .hero_form_row.select {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .checkbox-label {
    font-family: 'Encode Sans', sans-serif;
    color: #fff;
    font-size: 16px;
  }
  
  .submit-button {
    width: 500px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f58220;
    font-family: 'Encode Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

  .submit-button:hover {
    background-color: #d76501;
    color: white;
    cursor: pointer;
  }
  
  .select-field {
    border-radius: 12px;
    background-color: rgba(28, 36, 41, 0.6);
    font-family: 'Encode Sans', sans-serif;
    color: #fff;
  }
  
  .field-label {
    font-family: 'Encode Sans', sans-serif;
    color: #fff;
    font-weight: 400;
  }
  
  .hero_button_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .section-2 {
    height: 40vh;
    background-color: #fff;
  }
  
  .navbar {
    background-color: transparent;
  }
  
  .nav-link {
    font-family: 'Encode Sans', sans-serif;
    color: #2f63af;
    font-size: 16px;
    font-weight: 700;
  }
  
  .nav-link:hover {
    color: #f58220;
  }
  
  .navbar-2 {
    background-color: #fff;
    font-family: Lato, sans-serif;
    color: #2f63af;
    font-weight: 700;
  }
  
  .nav-link-2 {
    font-size: 18px;
  }
  
  .main-nav-link {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
  
  .main-nav-link:hover {
    color: #f58220;
  }
  
  .nav-link-3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  
  .login_bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 30px;
    max-height: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #013861;
  }
  
  .login-button-container {
    display: flex;
    width: 1170px;
    height: 30px;
    padding-right: 40px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .login_button {
    display: flex;
    width: 100px;
    height: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background-color: #f58220;
    cursor: pointer;
  }
  
  .login_button:hover {
    background-color: #2f63af;
  }
  
  .login_button_text {
    font-family: Lato, sans-serif;
    color: #fff;
    font-size: 12px;
  }
  
  .form-block {
    width: 100%;
  }
  
  .textarea {
    background-color: rgba(28, 36, 41, 0.6);
    font-family: 'Encode Sans', sans-serif;
    color: #fff;
  }
  
  .ticket_confirmation_text-container {
    max-width: 900px;
    font-size: 18px;
    line-height: 24px;
  }
  
  .ticket_confirmation_text {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-align: center;
  }
  
  .ticket_confirmation_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 70vh;
    padding-top: 140px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('../images/heroBackground.jpg');
    background-position: 0px 0px;
    background-size: cover;
  }
  
  @media screen and (max-width: 991px) {
    .logo {
      max-width: 90%;
    }
  
    .header {
      padding: 40px 0px 40px 20px;
    }
  
    .navigation {
      width: auto;
      height: 100px;
      padding-right: 0px;
    }
  
    .section-hero {
      height: 100vh;
      padding: 0px 40px;
    }
  
    .menu_container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      max-width: 900px;
      padding-right: 20px;
      padding-left: 0px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  
    .hero-container {
      min-width: 760px;
      padding-top: 10px;
      padding-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .hero-left {
      max-width: 80%;
    }
  
    .hero-right {
      width: 80%;
      max-width: none;
    }
  
    .hero-left-container {
      padding-right: 0px;
    }
  
    .heading {
      text-align: center;
    }
  
    .paragraph {
      font-size: 18px;
      line-height: 27px;
      text-align: center;
    }
  
    .heading-2 {
      font-size: 24px;
      line-height: 28.799999999999997px;
      text-align: center;
    }
  
    .bold-text {
      font-size: 18px;
    }
  
    .hero-right-container {
      padding-top: 40px;
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .navbar {
      height: 100px;
    }
  
    .nav-menu {
      position: fixed;
      display: block;
      width: 100px;
      height: 100px;
    }
  
    .image {
      max-width: none;
    }
  
    .icon {
      font-size: 60px;
    }
  
    .container {
      max-width: 9%;
      background-color: transparent;
    }
  
    .ticket_confirmation_text {
      max-width: 750px;
      min-width: 750px;
      font-size: 16px;
      line-height: 21px;
    }
  
    .ticket_confirmation_container {
      height: 50vh;
    }
  }
  
  @media screen and (max-width: 767px) {
    .header {
      padding-left: 0px;
    }
  
    .section-hero {
      height: 100vh;
      padding: 0px 10px 40px 10px;
    }
  
    .menu_container {
      width: auto;
      min-width: 460px;
    }
  
    .hero-container {
      width: 480px;
      padding-top: 20px;
    }
  
    .heading {
      margin-bottom: 20px;
      font-size: 28px;
    }
  
    .form-block {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .ticket_confirmation_text {
      width: 480px;
      max-width: 480px;
      min-width: 0px;
      padding-right: 20px;
      padding-left: 20px;
      font-size: 14px;
    }
    .submit-button {
      width: 480px;
    }

  }
  
  @media screen and (max-width: 479px) {

    .body {
      display: flex;
      flex-direction: column;
     align-items: center;
    }

    .section-hero {
      height: 100%;
      padding: 0px 10px 40px 10px;
    }
  
    .hero-container {
      min-width: 320px;
      background-color: rgba(0, 0, 0, 0.61);
      background-image: url('../images/heroBackground.jpg');
      background-position: 0px 0px;
      background-size: auto;
      display: flex;
      flex-direction: column;
     align-items: center;
    }
  
    .heading {
      font-size: 28px;
      line-height: 34px;
    }
  
    .form-block {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .ticket_confirmation_text {
      width: 320px;
    }
  
    .ticket_confirmation_container {
      height: 50vh;
      background-image: url('../images/heroBackground.jpg');
      background-position: 0px 0px;
      background-size: auto;
    }

    .submit-button {
      width: 320px;
    }


  }
  
  