.ownership_modal_body {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-color: rgba(72, 75, 78, 0.80);
    height: 100%;
  }
  
  .ownership_modal_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .ownership_modal_card {
    width: 400px;
    height: 250px;
    border-radius: 16px;
    background-color: var(--white);
  }
  
  .ownership_modal_icon_container {
    display: flex;
    height: 32%;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--blue);
  }
  
  .ownership_modal_button_container {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
  }
  
  .ownership_modal_message {
    padding: 20px 10px;
    font-family: var(--primary_font);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  
  .ownership_modal_dismiss_button {
    background-color: var(--gray);
    color: var(--black);
    font-family: var(--primary_font);
    font-weight: bold;
    border-radius: 5px;
  }
  .ownership_modal_dismiss_button:hover {
    background-color: var(--black);
    color: var(--white);
    cursor: pointer;
  }
  .ownership_modal_confirm_button {
    margin-left: 10px;
    background-color: var(--blue);
    color: var(--white);
    font-family: var(--primary_font);
    border-radius: 5px;
  }
  .ownership_modal_confirm_button:hover {
    background-color: var(--dk_gray);
    color: var(--white);
    cursor: pointer;
  }

  .error_icon {
    font-size: 18px;
    font-weight: bold;
    color: var(--lt_gray);
  }

  .owner_select {
    padding: 10px;
  }



  @media screen and (max-width: 479px) {
    .ownership_modal_card {
        width: 320px;
        height: 225px;
      }
    
      .ownership_modal_message {
        font-size: 16px;
      }
    
      .ownership_modal_dismiss_button {
        font-size: 12px;
      }
    }    

    .ownership_modal_field_value_editable {
        border: solid 1px  var(--lt_gray) ;
        width: 80%;
        padding: 5px;
    }